import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})
router.beforeEach((to,form,next)=>{
  store.commit('changeOpacity',to.meta.opacityMenu)
  next()
})
export default router