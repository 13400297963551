<template>
  <div class="active-side" v-if="visible">
    <img class="info" src="@/assets/image/action-side/pc/info.png" />
    <br>
    <img class="close" src="@/assets/image/action-side/pc/close.png" @click="visible = false" />
  </div>
</template>

<script>
export default {
  name: 'ActionSidePc',
  data() {
    return {
      visible: true
    }
  }
}
</script>

<style lang='less' scoped>
.active-side {
  position: fixed;
  bottom: 14px;
  right: 10px;
  text-align: center;
  .info {
    width: 240px;
  }
  .close {
    margin-top: 4px;
    width: 30px;
    cursor: pointer;
  }
}
</style>
