export default [
  {
    name: 'home',
    path: '/',
    component: () => import('../views/HomePage'),
    meta: {
      title: '首页',
      opacityMenu:true
    }
  },{
    name: 'product',
    path: '/product',
    component: () => import('../views/ProductService'),
    meta: {
      title: '产品服务',
      opacityMenu:true
    }
  },{
    name: 'deviceLock',
    path: '/device/lock',
    component: () => import('../views/device'),
    meta: {
      title: '智能门锁',
      opacityMenu: true
    }
  },{
    name: 'deviceMeter',
    path: '/device/meter',
    component: () => import('../views/device'),
    meta: {
      title: '智能水电表',
      opacityMenu: true
    }
  },{
    name: 'deviceAccess',
    path: '/device/access',
    component: () => import('../views/device'),
    meta: {
      title: '智能门禁',
      opacityMenu: true
    }
  },{
    name: 'deviceGate',
    path: '/device/gate',
    component: () => import('../views/device'),
    meta: {
      title: '车辆/人行闸道',
      opacityMenu: true
    }
  },{
    name: 'deviceAlarm',
    path: '/device/alarm',
    component: () => import('../views/device'),
    meta: {
      title: '烟雾/燃气报警器',
      opacityMenu: true
    }
  },{
    name: 'apartment',
    path: '/product/apartment',
    component: () => import('../views/ProductApartment'),
    meta: {
      title: '智慧公寓管理系统',
      opacityMenu:true
    }
  },{
    name: 'wisdomBI',
    path: '/product/wisdomBI',
    component: () => import('../views/ProductBI'),
    meta: {
      title: '智慧BI数据分析系统',
      opacityMenu:true
    }
  },{
    name: 'ProductRent',
    path: '/product/rent',
    component: () => import('../views/ProductRent'),
    meta: {
      title: '流动人口和出租房屋管理平台',
      opacityMenu:true
    }
  },{
    name: 'solution',
    path: '/solution',
    component: () => import('../views/Solution'),
    meta: {
      title: '解决方案',
      opacityMenu:true
    }
  },{
    name: 'solutionDetails',
    path: '/solutionDetails/:name',
    component: () => import('../views/SolutionDetails'),
    meta: {
      title: '解决方案详情',
      opacityMenu:true
    }
  },{
    name: 'plan',
    path: '/plan',
    component: () => import('../views/plan'),
    meta: {
      title: '信创方案',
      opacityMenu:true
    }
  },{
    name: 'news',
    path: '/news',
    component: () => import('../views/NewsCenter'),
    meta: {
      title: '新闻中心',
      opacityMenu:true
    }
  },{
    name: 'about',
    path: '/about',
    component: () => import('../views/AboutUs'),
    meta: {
      title: '关于我们',
      opacityMenu:true
    }
  },{
    name: 'details',
    path: '/details/:id',
    component: () => import('../views/NewsDetails'),
    meta: {
      title: '新闻详情',
      opacityMenu:false
    }
  }
]