<template>
  <div class="container">
    <div :class="[{ isopacity: isOpacity }, 'pc']">
      <div class="header_con">
        <div class="logo">
          <router-link :to="{ name: 'home' }"
            ><img :src="src" alt=""
          /></router-link>
        </div>
        <div class="nav">
          <a-menu v-model="current" mode="horizontal" @click="handleClick">
            <template v-for="item in options">
              <a-menu-item :key="item.name" v-if="!item.child">{{
                item.label
              }}</a-menu-item>
              <a-sub-menu :key="item.name" v-else>
                <template #title>
                  <div class="flex-center">
                    {{ item.label }}
                    <a-icon
                      type="down"
                      style="font-size: 10px; margin-right: 0"
                    />
                  </div>
                </template>
                <a-menu-item
                  v-for="(subItem, index) in item.child"
                  :key="subItem.name"
                  >{{ subItem.label.replace('\n', '') }}</a-menu-item
                >
              </a-sub-menu>
            </template>
          </a-menu>
        </div>
        <a href="https://saas.yxpms.com" class="login">登录</a>
        <a-button type="primary" size="large" class="try">
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
            >免费试用</a
          >
        </a-button>
      </div>
    </div>
    <div class="mobile">
      <div class="header">
        <div class="a-btn" @click="visible = true">
          <a-icon type="menu" />
        </div>
        <router-link :to="{ name: 'home' }"
          ><img src="../assets/image/logo_1.png" alt=""
        /></router-link>
      </div>
      <a-drawer
        placement="left"
        :closable="true"
        :visible="visible"
        @close="visible = false"
      >
        <div class="drawer_con">
          <template v-for="(i, index) in options">
            <a-collapse
              :key="index"
              v-model="activeKey"
              v-if="i.child"
              accordion
              :bordered="false"
              expandIconPosition="right"
            >
              <a-collapse-panel
                :key="i.name"
                :header="i.label"
                :style="customStyle"
              >
                <p
                  v-for="(item, index2) in i.child"
                  :key="index2"
                  @click="visible = false"
                  class="menu_item"
                  :class="{n: item.label.indexOf('\n') !== -1}"
                >
                  <span
                    :class="{ active: current == item.name }"
                    v-if="item.path"
                    @click.stop="moblieClick(item)"
                  >
                    {{ item.label }}
                  </span>
                  <span v-else :class="{ active: current == item.name }">
                    <router-link :to="{ name: item.name }">{{
                      item.label
                    }}</router-link>
                  </span>
                </p>
              </a-collapse-panel>
            </a-collapse>
            <p
              :class="{ active: current == i.name }"
              v-else
              :key="index"
              class="menu_item"
              @click="visible = false"
            >
              <router-link :to="{ name: i.name }">{{ i.label }}</router-link>
            </p>
          </template>
        </div>
      </a-drawer>
    </div>
  </div>
</template>

<script>
export default {
  name: "PublicHeader",

  data() {
    return {
      options: [
        {
          name: "home",
          label: "首页",
        },
        {
          name: "product",
          label: "产品服务",
          child: [
            // {
            //   name: "product",
            //   label: "产品服务",
            // },
            {
              name: "apartment",
              label: "智慧公寓管理系统",
            },
            {
              name: "wisdomBI",
              label: "智慧BI数据分析系统",
            },
            {
              name: "ProductRent",
              label: "流动人口和出租房屋\n管理平台"
            }
          ],
        },
        {
          label: '智能设备',
          child: [
            {
              name: 'deviceLock',
              label: '智能门锁'
            },
            {
              name: 'deviceMeter',
              label: '智能水电表'
            },
            {
              name: 'deviceAccess',
              label: '智能门禁'
            },
            {
              name: 'deviceGate',
              label: '车辆/人行闸道'
            },
            {
              name: 'deviceAlarm',
              label: '烟雾/燃气报警器'
            }
          ]
        },
        {
          name: "solution",
          label: "解决方案",
          child: [
            {
              path: "solutionDetails",
              name: "dichan",
              label: "保障性租赁住房",
            },
            {
              path: "solutionDetails",
              name: "rencai",
              label: "政府人才公寓",
            },
            {
              path: "solutionDetails",
              name: "shangye",
              label: "地产品牌公寓",
            },
            {
              path: "solutionDetails",
              name: "xitong",
              label: "企业园区宿舍",
            },
          ],
        },
        {
          name: "plan",
          label: "信创方案",
        },
        {
          name: "news",
          label: "新闻中心",
        },
        {
          name: "about",
          label: "关于我们",
        },
      ],
      current: [],
      customStyle: {
        border: 0,
        color: "#333",
        fontSize: "16px",
      },
      activeKey: null,
      visible: false,
    };
  },
  props: ["isOpacity"],
  computed: {
    src() {
      const logo1 = require("../assets/image/logo_1.png");
      const logo2 = require("../assets/image/logo_opacity.png");
      return this.isOpacity ? logo2 : logo1;
    },
  },
  methods: {
    handleClick(e) {
      console.log("eeeeee", e);
      if (
        ["shangye", "rencai", "dichan", "xitong"].includes(e.key) &&
        this.$route.params.name != e.key
      ) {
        this.$router.push({
          name: "solutionDetails",
          params: { name: e.key },
        });
        return;
      }
      if (e.key && e.key != this.$route.name) {
        this.$router.push({ name: e.key });
      }
    },
    moblieClick(e) {
      console.log("eee", e);
      if (e.path == "solutionDetails") {
        this.$router.push({
          name: "solutionDetails",
          params: { name: e.name },
        });
      }
      this.visible = false;
    },
    handleScroll() {
      if (this.$route.name != "details") {
        const scrollTop =
          document.documentElement.scrollTop ||
          window.pageYOffset ||
          document.body.scrollTop;
        let sh = this.$route.name == "home" ? 200 : 100;
        this.$store.commit("changeOpacity", scrollTop < sh);
      }
    },
  },
  watch: {
    $route(to) {
      if (to.name == "solutionDetails") {
        this.current = [to.params.name];
        return;
      }
      this.current = [to.name];
    },
  },
  mounted() {
    window.onscroll = () => {
      this.handleScroll();
    };
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 768px) {
  .pc {
    display: none;
  }
  .mobile {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .pc {
    display: block;
  }
  .mobile {
    display: none;
  }
}
.pc {
  width: 100%;
  height: 72px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0px 7px 22px 0 rgba(0, 0, 0, 0.08);
  .header_con {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .logo {
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 18px;
    }
    .nav {
      position: absolute;
      right: 150px;
      padding-top: 10px;
      .ant-menu-horizontal {
        border-bottom: none;
      }
      .ant-menu-item {
        padding: 0;
        margin: 0 20px;
        font-size: 16px;
      }
      .ant-menu-submenu {
        font-size: 16px;
      }
      .ant-menu-item-selected {
        color: inherit;
      }
    }
    .login {
      position: absolute;
      right: 0;
      color: rgba(0, 0, 0, 0.65);
      padding-top: 10px;
      line-height: 48px;
      font-size: 16px;

      &:first-of-type {
        right: 100px;
      }

      &:hover {
        color: #1890ff !important;
      }
    }
    .try {
      position: absolute;
      top: 14px;
      right: -24px;
      a {
        display: block;
      }
    }
  }
}
.mobile {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  .header {
    padding: 12px;
    height: 50px;
    background-color: #fff;
    box-shadow: 0px 7px 22px 0 rgba(0, 0, 0, 0.08);
    img {
      width: 90px;
      float: right;
    }
    .a-btn {
      float: left;
      color: #333;
      font-size: 18px;
    }
  }
}
.drawer_con {
  padding-top: 40px;
  .menu_item {
    line-height: 46px;
    padding-left: 16px;
    font-size: 16px;
    margin-bottom: 0;
    &.n {
      line-height: 26px;
      margin-top: 10px;
    }
    a {
      color: #333;
    }
  }
  .active {
    color: #1890ff;
    a {
      color: #1890ff !important;
    }
  }
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.isopacity {
  background-color: transparent;
  box-shadow: 0px 7px 22px 0 rgba(0, 0, 0, 0);
  ul {
    color: #fff;
    background-color: transparent;
  }
  .login {
    color: #fff !important;
  }
}
/deep/ .ant-menu {
  background-color: transparent;
}
/deep/ .ant-collapse.ant-collapse-borderless {
  background-color: #fff;
}
</style>
